.background_grey {
  width: var(--100vw);
  height: var(--100vh);
  position: absolute;
  //background-color: #F0F0F0;
  background-color: #EDEEF0;
  z-index: -2;
}

.vh_frame {
  display: flex;
  flex-direction: column;
  height: var(--100vh);
}

.frame {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top {
  flex: 0;
}

.top_b {
  border-bottom: solid 1px #DDDDDD;
}

.body {
  overflow: auto;
  flex: 1;
}

.bottom {
  flex: 0;
}