.clue_frame{
  display: flex;
  flex-direction: column;
  height: var(--100vh);
}

.frame {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top {
  flex: 0;
  padding: 20px 24px 0 24px;
}

.body {
  overflow: auto;
  flex: 1;
}

.bottom {
  flex: 0;
}


