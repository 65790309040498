.adm-list-item-disabled.adm-list-item-disabled > .adm-list-item-content > * {
    opacity: 1.0;
}

.adm-capsule-tabs-header {
    flex: 0;
}

.adm-capsule-tabs-content {
    overflow: auto;
    flex: 1;
}

.adm-scroll-mask-left {
    background: linear-gradient(to right, #EDEEF0, rgba(255, 255, 255, 0))
}

.adm-scroll-mask-right {
    background: linear-gradient(to left, #EDEEF0, rgba(255, 255, 255, 0))
}