.background_circle {
  --width: 880vw;
  position: absolute;
  height: var(--width);
  width: var(--width);
  left: calc((var(--width) - var(--100vw)) * -1 / 2);
  top: calc(var(--width) * -0.88 + 1vw);
  border-radius: 50%;
  background-color: var(--adm-color-primary);
  z-index: -1;
}

.background_circle_pc {
  position: absolute;
  height: 50vh;
  width: var(--100vw);
  background-color: var(--adm-color-primary);
  z-index: -1;
}

