.customer-tab-frame {
    display: flex;
    flex-direction: column;
    height: 100%
}

div.adm-capsule-tabs-content {
    overflow: auto;
    flex: 1;
}

.adm-capsule-tabs-content {
    padding: 0;
}

.adm-index-bar-sidebar {
    right: -15px
}

.adm-list-item-content-arrow {
    margin-right: 20px;
}

