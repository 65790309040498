.app {
  height: var(--100vh);
  display: flex;
  flex-direction: column;
}

.appSafari {
  //height: calc(var(--100vh) - env(safe-area-inset-bottom));
  height: var(--100vh);
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
}

.body {
  flex: 1;
  display: flex;
  overflow: auto;
}

.bottom {
  flex: 0;
  border-top: solid 1px #DDDDDD;
  background: #E0E0E0;
}