html {
    /*zoom: 50%;*/
    transform: scale(0.5);
    transform-origin: 0 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*html[data-prefers-color-scheme='dark'] body {*/
/*    background: #262629;*/
/*}*/

/*html body {*/
/*    background: #EEF0F3;*/
/*    transition: none;*/
/*}*/

/*html html {*/
/*    touch-action: manipulation;*/
/*}*/

/*html #root .__dumi-default-mobile-demo-layout {*/
/*    padding: 0;*/
/*}*/

/*html html {*/
/*    min-height: var(--100vh);*/
/*}*/

.adm-list-item-disabled.adm-list-item-disabled > .adm-list-item-content > * {
    opacity: 1.0;
}

.adm-capsule-tabs-header {
    flex: 0;
}

.adm-capsule-tabs-content {
    overflow: auto;
    flex: 1;
}
